

.desertscenetext {
    color: aliceblue;
  font-weight: bold;
  font-size: 16px;
  font-size: 1.95vw;;
  top: 45px;
  left: 100px;
  position: absolute;
  margin-top: 8.5vh;
  margin-left: 11vw;
}
.desertscene img {
    max-width: 100%;
  width: 100vw;
  height: auto;
  object-fit: cover;
}