.drinkscene {
    font-weight: bold;
    font-size: 1em;
    color: aliceblue;
    
}
.row {
    display:flex;
    cursor: pointer;
    
}

.done-drinking {
   text-align: center; 
   cursor: pointer;
}
.column {
    flex: 50%;
  padding: 10px;
  cursor: pointer;
}

.drinkscene li {
    font-size: 1em;
    color: aliceblue;
    margin: 10px 5px;
    
}
.drinkscene drinkslist {
    columns: 2;
    cursor: pointer;
    list-style-type: none;
    justify-content: center;
    text-justify: distribute;
    
}
.dranktomuch {
    font-size: 1em;
    color: aliceblue;
    margin: 10px 5px;
    cursor: pointer;
}

.dranktomuch  p {
    font-size: 1.2em;
    color: aliceblue;
    margin: 10px 5px;
    cursor: pointer;
}


    