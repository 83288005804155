@import url('https://fonts.googleapis.com/css?family=Major Mono Display');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Major Mono Display', monospace;
}

body {
  margin: 0;
  background-color: #530c27;
}

