.map-scene {
    position: absolute;
  top: 45px;
  left: 0;
}

.map-scene img {
    max-width: 100%;
    width: 100vw;
    height: auto;
    object-fit: cover;
}