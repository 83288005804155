.tvmask {
  position: absolute;
  top: 45px;
  left: 0;
}
.tvmask img {
  width: 100vw;
  height: auto;
  object-fit: cover;
  z-index: 200;
}

.splashscreenmask {
  position: absolute;
  top: 50px;
  left: 10px;
}
.splashscreenmask img {

  width: 100vw;
  height: auto;
  object-fit: cover;
  z-index: 1;
}
