.saloonscene {
    position: absolute;
  top: 45px;
  left: 0;
}
.saloonscene img {
    max-width: 100%;
  width: 100vw;
  height: auto;
  object-fit: cover;
}


.saloonscene li {
    font-size: 1em;
    color: aliceblue;
    margin: 10px 5px;
}
.saloonscene ul {
    cursor: pointer
}
.saloonscenetext {
    color: aliceblue;
    font-weight: bold;
    font-size: 16px;
    font-size: 1.95vw;;
    top: 45px;
    left: 0;
    position: absolute;
    margin-top: 8.5vh;
    margin-left: 11vw;
} 