
/* Style the top navigation bar */
.top-bar  {
  overflow: hidden;
  background-color: #333;
}

/* Style the topnav links */
.top-bar  a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change color on hover */
.top-bar  a:hover {
  background-color: #530c27;
  color: black;
}

.top-bar  button {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change color on hover */
.top-bar  button:hover {
  background-color: #ddd;
  color: black;
}