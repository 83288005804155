.desertchoices button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-size: 1.95vw;
  margin: 4px 2px;
  cursor: pointer;
}
.desertchoices input {
  width: 20%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  padding: 12px 20px;
  resize: vertical;
  font-size: 16px;
  font-size: 1.95vw;
}

.runbutton button {
    background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-size: 1.95vw;
  margin: 4px 2px;
  cursor: pointer;

  cursor: pointer;
}
