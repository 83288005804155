@font-face {
  font-family: "PixelCowboy";
  src: url("../../fonts/Pixel Cowboy.otf") format("opentype");
}
.tvtext {
  color: aliceblue;
  font-weight: bold;
  font-size: 16px;
  font-size: 1.95vw;;
  top: 45px;
  left: 0;
  position: absolute;
  margin-top: 8.5vh;
  margin-left: 11vw;
}
.openingscene {
  position: absolute;
  top: 45px;
  left: 0;
}
.openingscene img{
  
  max-width: 100%;
  width: 100vw;
  height: auto;
  object-fit: cover;
}

html {
  font-family: "PixelCowboy";
}

input {
  font-family: "PixelCowboy"
}
button {
  font-family: "PixelCowboy"
}




